export default {
	namespaced: true,
	state: {
		//
		qTranslateChange: localStorage.getItem('Lang') || navigator.language || navigator.userLanguage,
	},
	getters: {},
	mutations: {
		/***
         * @todo translate app in english or in french
		 * @param [state, data]
		 */
		TRANSLATE_APP(state, data) {
			state.qTranslateChange = data;
		},

	},
	actions: {},
};
