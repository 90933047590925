import Vue from 'vue'
import '@/libs/sweet-alerts'
import setAuthHeader from "../src/auth/jwt/token"
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import CustomGoogleAutocomplete from 'vue-custom-google-autocomplete'
//localisation
import VueTelInput from 'vue-tel-input'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import HighchartsVue from 'highcharts-vue'



import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './global-components'


// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// import Vue from "vue"
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// import App from "./App.vue"

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Install components globally
Vue.use(BootstrapVue)
Vue.use(CustomGoogleAutocomplete)
Vue.use(HighchartsVue)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

// new Vue({
//   render: h => h(App)
// }).$mount("#app")

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueI18n)
Vue.use(Vuex)

// Composition API
Vue.use(VueCompositionAPI)

//localisation
Vue.use(VueTelInput)

// Sweetalert
Vue.use(VueSweetalert2);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import assets customize css
require('@/assets/css/customize.css')

//localisation
import 'vue-tel-input/dist/vue-tel-input.css';
import { qFrench } from './translate/french';
import { qEnglish } from './translate/english';

// Ready translated locale messages
const messages = {
  fr: {
    message : qFrench(),
  },
  en: {
    message :  qEnglish(),
  }
}

const i18n = new VueI18n({
  locale: store.state.qTranslate.qTranslateChange, // set locale
  messages, // set locale messages
})

Vue.config.productionTip = false
if (localStorage.getItem('token')) {
  setAuthHeader(localStorage.getItem('token'))
} else {
  setAuthHeader(false)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
