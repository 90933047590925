import axios from 'axios';
import { toast_orign } from '@/utils/qToast';
import moment from 'moment';

const { default: URL } = require('@/views/pages/request');
const { qCrypt, qCryptJson } = require('./qeCrypt');

class qcEntreprise {
   constructor() {}

   async info() {
      const { data } = await axios.get(URL.ENTREPRISE_INFO);

      if (data) {
         // Devis
         const cryptData = qCrypt(data[0].devise[0].libelle);
         // const cryptData__pass = qCrypt(data[0].nombre_jour_restant)
         localStorage.setItem('entreprise_info', cryptData);
         localStorage.setItem('qhze_022', qCryptJson(data[0]));
      }
   }

   async limitedSubcrible(dataList, limit) {
      const { data } = await axios.get(URL.ENTREPRISE_INFO);
      let isLimited = true;

      if (data) {
         //moment.utc().toDate().toString()

         const DataTileUid = (date) => {
            return {
               Month:
                  moment(date)
                     .toDate()
                     .getMonth() + 1,
               YearFull: moment(date)
                  .toDate()
                  .getFullYear()
                  .toString(),
            };
         };

         console.log('Month, YearFull', [
            DataTileUid(
               moment
                  .utc()
                  .toDate()
                  .toString()
            ).Month,
            DataTileUid(
               moment
                  .utc()
                  .toDate()
                  .toString()
            ).YearFull,
         ]);

         dataList.forEach((element) => {
            element.test = DataTileUid(
               moment
                  .utc()
                  .toDate()
                  .toString()
            ).Month;
         });

         //   dataList.forEach(element => {
         //    element.date = ''

         //   })

         //   [DataTileUid(moment.utc().toDate().toString()).Month, DataTileUid(moment.utc().toDate().toString()).YearFull]

         console.log('dataList', dataList);

         const getDataCreateInMonth = dataList.filter((list) => {
            console.log(list.test);

            return (
               list.test[1] ===
               DataTileUid(
                  moment
                     .utc()
                     .toDate()
                     .toString()
               ).YearFull
            );
         });

         console.log(getDataCreateInMonth);

         //   console.log(getDataCreateInMonth)
         // if(getDataCreateInMonth > limit) {
         //   isLimited = true;
         //   return {
         //      isLimited: isLimited,
         //   };
         // }
      }

      return {
         isLimited: isLimited,
      };
   }

   qAccessForbidden(root) {
      toast_orign(
         root,
         'Accès interdit',
         'AlertTriangleIcon',
         "Vous n'avez pas les permissions pour effectuer cette action, veillez contactez votre adminstrateur",
         'warning',
         'top-right'
      );
   }

   qUpgardeToAccess(root) {
      toast_orign(
         root,
         'Mise à niveau',
         'AwardIcon',
         'Pour pouvoir faire quelconque opération veillez, mettre à niveau votre profil',
         'primary',
         'top-right'
      );
   }
}

export default new qcEntreprise();
