export const qEnglish = () => {
  return {
    // Navigation

    // Sidebar
    sidebar: {
      dashboard: "Dashboard",
      billing: {
        header: "Billing",
        invoice: "Invoice",
        quotes: "Quotes",
        relance: "Relaunch",
        taxe: "Taxe",
      },
      stock_management: {
        header: "stock management",
        my_stocks: "My stocks",
        my_sales: "My sales",
        my_supply: "My supply",
        supplyThreshold: "Supply threshold",
        inventaire: "Inventaire",
      },
      Treasury: {
        header: "Treasury",

        //
        expense: "Expense",
        simple_expense: "Simple expense",
        type_expense: "Type of expense",

        transaction: "Transaction",
        accounts: "Accounts",
        loan: "Loan",
      },
      catalog: {
        header: "Catalog",
        catalog: "My Catalog",
        category: "Category",
        article: "Articles",
      },
      crm: {
        header: "CRM",
        client: "Client",
        provider: "Provider",
        prospecting: "Prospecting",
        prospect: "Prospect",
      },
      company: {
        header: "Company",
        presonal: "Personal",
        project: "Project",
      },
      support: {
        header: "Support",
        ticket: "tickets",
      },
      setting: {
        header: "Setting",
        permissions: "Permissions",
      },
    },

    login: {
      header: "Welcome to Ediqia",
      subHeader: "Log in to start the adventure",

      rheader: "The adventure begins here",
      rsubHeader: "Increase your performance",

      eheader: "Let's go",
      esubHeader: "Create your organization",

      input: {
        firstName: "First name",
        lastName: "Last name",
        username: "username",
        companyID: "organization's identifier",
        email: "Email",
        password: "Password",
        forq: "Forgot password?",
        location: "Localization",
        contact: "Contact",
        acceptContract: "By continuing you agree to",
        NameOrganization: "Name of your organization",
        FieldOfActivity: "Field of activity",
        Size: "Size",
        StaffMembers: "Staff members",
        SiteWeb: "Site internet",
        Currency: "Currency",
        FixedPhone: "Fixed phone",
        file: "Logo of your organization",
        phPassword: "Enter your password",
        phFieldOfActivity: "Selected your Field of activity",
        phSize: "Selected the size of organization",
        phCurrency: "Selected the currency of your country",
        phLocation: "Enter your localization",
        phFile: "Select the logo of your organization",
      },
      generalTerms: "the general terms of use",
      privacyPolicy: "the privacy policy",
      generalTermsSales: "the general terms of sale",
      btn_login: "Log in",
      remenber: "Remember me",
      btn_login: "Login",
      is_login: "Already have an account?",
      btn_create: "Create an account",
      register: "Register",
      createYourOrganization: "Create your organization",
    },

    connexion: {
      logout: "Logout",
      profil: "Profil",
      "my-account": "My account",
      "my-company": "My company",
      qRole__g: "Manager",
      qRole__e: "employer",
      upgrade: "Upgrade",
    },

    navbar: {
      fast_action: "Fast action",
      createInvoice: "Create an invoice",
      createQuote: "Create a quote",
      createLoan: "Create a loan",
      createSupply: "Create a supply",
      createInventory: "Create an inventory",
      createExpense: "Create an expense",
      createSimpleExpense: "Create a simple expense",
      createTypeExpense: "Create a type of expense",
      createAccount: "Create an account",
      createCategory: "Create a category",
      createArticle: "Create an article",
      createClient: "Create a client",
      createProvider: "Create a provider",
      createProspect: "Create a prospect",
      createProspecting: "Create a prospecting",
      createProject: "Create a project",
      createPersonal: "Create a personal",
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    dashboard: {
      header: "Dashboard",
      annualTurnover: "Annual Turnover",
      lastMonth: "Last Month",
      currentMonth: "Current Month",
      turnover: "Turnover",
      expenses: "Expenses",
      income: "Income",

      //Devis à relancer
      quotes: "Quotes",
      amount: "Amount",
      nombre: "Number",
      deadlines: "Deadlines",
      etat: "State",
      voir: "preview",
      Impayés: "Unpaid",
      paye: "Paid",
      topaid: "To be paid",
      Partiellement_payé: "Partial",

      //Vos dernières factures
      your_last_invoice: "Your last invoices",
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    tableColumns: {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      city: "City",
      country: "Country",
      action: "Action",
      amount: "Amount",
      date: "Date",
      state: "State",
      status: "Status",
      details: "Details",
    },

    account: {
      header: "My account",
      sub: "Manage your accounts",
      Balance: "Balance",
      seeMore: "See more",
      number: " Number ",
    },

    // Statistic

    statistic: {
      header: "Statistics",
      sub: "",
      best_customers: "Best customers",
      best_sold: "Best sold",
      amount_sold: "Amount sold",
      quantity: "Quantity",
    },
    statistic_Date: {
      mois_en_cours: "Current Month",
      mois_precedent: "Last Month",
      annee_en_cours: "Current Year",
      annee_precedente: "Last Year",
      debut_la_creation: "Start of the creation",
      fin_la_creation: "End of the creation",
      this_year: "This year",
    },

    statistic_chart: {
      amount_sold: "Montant des ventes",
      quantity: "Quantité",
    },

    // Facturation
    invoice: {
      pay : 'Pay',
      register : 'Register',
      draft: 'Draft',
      new_invoice: 'New invoice'
    },

    //depense

    depense: {
      List_of_Expenses: "List of Expenses",
      make_an_expense: "make an expense",

      List_of_simple_expenses: "List of simple expenses",

      Expenses: "Expenses",
      Type_of_expense: "Type of expense",

      Description_of_the_expense: "Description of the expense",

      Release_date: "Release date",

      Expense_amount: "Expense amount",

      Reason_for_the_expense: "Reason for the expense",

      Vendor: "Vendor",
      supplier_invoice_number: "supplier invoice number",

      supplier_invoice_file:
        "supplier invoice file",

      Recipient_of_the_expense: "Recipient of the expense",

      Payment_of_expenses: "Payment of expenses",

      Payment_date: "Payment date",
      Amounts: "Amounts",
      Account: "Account",
      Note: "Note",
      Add:"Add",
      Cancel:"Cancel",
      Add_a_regulation:"Add a regulation",
      Employees: "Employees",
Projects: "Projects",
Departments: "Departments",
Agencies: "Agencies",
Entries:"Entries",
New_expenses: "New expenses"
    },
  };
};
