import Vue from 'vue';
import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router'
import store from './../store'
import { qFrench } from './../translate/french';
import { qEnglish } from './../translate/english';
import { qCryptJson, qDecrypted } from "@/services/qeCrypt";


Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [

		// Supports
		{
			path: '/supports/tickets',
			name: 'tickets',
			component: () => import('@/views/pages/supports/tickets.vue'),
			meta: {
				title: 'Tickets',
				pageTitle: 'Envoyer un tickets',
				breadcrumb: [
					{
						text: 'Envoyer un tickets',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/supports/chat',
			name: 'Chat',
			component: () => import('@/views/pages/chat/qChat.vue'),
			meta: {
				title: 'chat',
				pageTitle: 'Chat',
				breadcrumb: [
					{
						text: 'Discuter avec un support',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		// Verication paiement
		{
			path: '/payment/verification',
			name: 'paymentRedirect',
			component: () => import('@/views/pages/paiement/redirectAfterPay.vue'),
			meta: {
				pageTitle: 'payment verification',
				layout: 'full',
				requiresAuth: true,
			},
		},
		


			//Gestion des inventaires
			{
				path: '/inventaire',
				name: 'inventaire',
				component: () => import('@/views/pages/dashboard/inventaire/liste_inventaire.vue'),
				meta: {
					title: 'liste des inventaires',
					pageTitle: 'Liste des inventaires',
					breadcrumb: [
						{
							text: 'Liste des inventaires',
							active: true,
						},
					],
					requiresAuth: true,
				},
			},

			{
				path: '/inventaire-create',
				name: 'inventaire-create',
				component: () => import('@/views/pages/dashboard/inventaire/inventaire.vue'),
				meta: {
					title: 'Créer un inventaires',
					pageTitle: 'Créer un inventaires',
					breadcrumb: [
						{
							text: 'Créer un inventaire',
							active: true,
						},
						{
							text: 'Liste des inventaires',
							to: { name: 'inventaire' },
						},
					],
					requiresAuth: true,
				},
			},


			{
				path: '/preview-inventaire',
				name: 'preview-inventaire',
				component: () => import('@/views/pages/dashboard/inventaire/preview_inventaire.vue'),
				meta: {
					title: 'Detail de l\'inventaire',
					pageTitle: 'Detail de l\'inventaires',
					breadcrumb: [
						{
							text: 'Details',
							active: true,
						},
						{
							text: 'Liste des inventaires',
							to: { name: 'inventaire' },
						},

						{
							text: 'Ajouter un inventaire',
							to: { name: 'inventaire-create' },
						},
					],
					requiresAuth: true,
				},
			},
	
	
	

		//Gestion des stoks
		{
			path: '/gestion-des-stocks/stocks',
			name: 'gsMyStocks',
			component: () => import('@/views/pages/inventoryManagement/myStocks.vue'),
			meta: {
				title: 'Mes stoks',
				pageTitle: 'Mes stocks',
				breadcrumb: [
					{
						text: 'Liste des stocks',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		{
			path: '/gestion-des-stocks/stocks/details',
			name: 'gsMyStockDetails',
			component: () => import('@/views/pages/inventoryManagement/details/myStock.vue'),
			meta: {
				title: 'stock',
				pageTitle: 'stock',
				breadcrumb: [
					{
						text: 'Detail de stock',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/gestion-des-stocks/ventes',
			name: 'gsMySales',
			component: () => import('@/views/pages/inventoryManagement/mySales.vue'),
			meta: {
				title: 'Mes ventes',
				pageTitle: 'Mes ventes',
				breadcrumb: [
					{
						text: 'Liste des ventes',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		{
			path: '/gestion-des-stocks/approvisionnements',
			name: 'gsMySupply',
			component: () => import('@/views/pages/inventoryManagement/mySupply.vue'),
			meta: {
				title: 'Mes approvisionnement',
				pageTitle: 'Mes approvisionnement',
				breadcrumb: [
					{
						text: 'Liste des approvisionnements',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		{
			path: '/gestion-des-stocks/seuil-approvisionnement',
			name: 'gsSupplyThreshold',
			component: () => import('@/views/pages/inventoryManagement/mySupplyThreshold.vue'),
			meta: {
				title: 'Seuil approvisionnement',
				pageTitle: 'Seuil approvisionnement',
				breadcrumb: [
					{
						text: 'Alerts',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		{
			path: '/gestion-des-stocks/approvisionnement/create',
			name: 'gsMySupplyStore',
			component: () => import('@/views/pages/inventoryManagement/created/mySupplyStore.vue'),
			meta: {
				title: 'Creation',
				pageTitle: 'Creation',
				breadcrumb: [
					{
						text: 'Creation des approvisionnements',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		// Route vers l'accueil
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/pages/dash/index.vue'),
			meta: {
				title:  store.state.qTranslate.qTranslateChange !== 'fr-FR' ? qEnglish().dashboard.header : qFrench().dashboard.header				,
				pageTitle:  store.state.qTranslate.qTranslateChange !== 'fr-FR' ? qEnglish().dashboard.header : qFrench().dashboard.header	,
				breadcrumb: [
					{
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page configuration
		{
			path: '/typeparametres',
			name: 'typeparametres',
			component: () =>
				import('@/views/pages/dashboard/parametre/type_parametre.vue'),
			meta: {
				pageTitle: 'Parametrage',
				breadcrumb: [
					{
						text: 'Liste des types parametres',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/parametres',
			name: 'parametres',
			component: () =>
				import('@/views/pages/dashboard/parametre/parametre.vue'),
			meta: {
				pageTitle: 'Parametrage',
				breadcrumb: [
					{
						text: 'Liste des parametres',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers la page d'inscription
		{
			path: '/register',
			name: 'register',
			component: () => import('@/views/pages/auth/register/Register.vue'),
			meta: {
				layout: 'full',
			},
		},
		// Route vers la page de connexion
		{
			path: '/entreprise',
			name: 'entreprise',
			component: () => import('@/views/pages/auth/register/Entreprise.vue'),
			meta: {
				layout: 'full',
				requiresAuth: true,
			},
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/pages/auth/login/Login.vue'),
			meta: {
				layout: 'full',
			},
		},
		// Route vers la page de reintialisation de mot de passe
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import('@/views/pages/auth/passwordReset/emailForm.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/new-password',
			name: 'new-password',
			component: () => import('@/views/pages/auth/newPassword/NewPassword.vue'),
			meta: {
				layout: 'full',
			},
		},

		// Route vers la page de reintialisation ID compte
		{
			path: '/reset-IdCompte',
			name: 'reset-IdCompte',
			component: () => import('@/views/pages/auth/codeReset/codeResetForm.vue'),
			meta: {
				layout: 'full',
			},
		},
		// Route vers la page des taxes
		{
			path: '/taxes',
			name: 'taxes',
			component: () => import('@/views/pages/dashboard/taxe/Taxe.vue'),
			meta: {
				pageTitle: 'Taxes',
				breadcrumb: [
					{
						text: 'Liste des taxes',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers la page de definition des echeances
		{
			path: '/echeances/create',
			name: 'taxes',
			component: () => import('@/views/pages/dashboard/facture/echeances.vue'),
			meta: {
				pageTitle: 'Échéances',
				breadcrumb: [
					{
						text: 'écheances',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page detail entreprise
		{
			path: '/profile/entreprise',
			name: 'Détail Entreprise',
			component: () => import('@/views/pages/dashboard/entreprise/profil.vue'),
			meta: {
				pageTitle: 'Détail Entreprise',
				breadcrumb: [
					{
						text: 'Détail Entreprise',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		// Route vers la page entête entreprise
		{
			path: '/entete/facture',
			name: 'Détail Entreprise',
			component: () => import('@/views/pages/dashboard/entreprise/EnteteEntreprise.vue'),
			meta: {
				pageTitle: 'Entête',
				breadcrumb: [
					{
						text: 'facture',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page profil de l'utilisateur connecté
		{
			path: '/profile',
			name: 'profile',
			component: () => import('@/views/pages/dashboard/profile/profile2.vue'),
			meta: {
				pageTitle: 'Profile',
				breadcrumb: [
					{
						text: 'Mon Profil',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page des comptes
		{
			path: '/comptes',
			name: 'comptes',
			component: () => import('@/views/pages/dashboard/compte/compte.vue'),
			meta: {
				pageTitle: 'Comptes',
				breadcrumb: [
					{
						text: 'Liste des comptes',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


			// Route vers la page des comptes
			{
				path: '/transfert',
				name: 'transfert',
				component: () => import('@/views/pages/dashboard/transfert/transfert.vue'),
				meta: {
					pageTitle: 'Liste des tranferts',
					breadcrumb: [
						{
							text: 'Transfert',
							active: true,
						},
					],
					requiresAuth: true,
				},
			},

		// Route vers la page des versement
		{
			path: '/versements',
			name: 'versements',
			component: () =>
				import('@/views/pages/dashboard/versement/versement.vue'),
			meta: {
				pageTitle: 'Versements',
				breadcrumb: [
					{
						text: 'Liste des versements',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/pack',
			name: 'pack',
			component: () => import('@/views/pages/pricing/pack.vue'),
			meta: {
				pageTitle: 'Pack',
				layout: 'full',
				requiresAuth: true,
			},
		},
		// Route vers la page des clients
		{
			path: '/clients',
			name: 'clients',
			component: () => import('@/views/pages/dashboard/client/Client.vue'),
			meta: {
				pageTitle: 'Clients',
				breadcrumb: [
					{
						text: 'Liste des clients',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers les details d'un client
		{
			path: '/clients/detail',
			name: 'détail client',
			component: () =>
				import('@/views/pages/dashboard/client/detail/ClientDetail.vue'),
			meta: {
				pageTitle: 'Client',
				breadcrumb: [
					{
						text: 'Détail de client',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/list',
			name: 'apps-users-list',
			component: () => import('@/views/apps/user/users-list/UsersList.vue'),
		},

		// Route vers la page des articles
		{
			path: '/articles',
			name: 'articles',
			component: () => import('@/views/pages/dashboard/article/article.vue'),
			meta: {
				pageTitle: 'Articles',
				breadcrumb: [
					{
						text: 'Liste des article',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page catalogue
		{
			path: '/catalogues',
			name: 'catalogue',
			component: () => import('@/views/pages/dashboard/article/catalogue.vue'),
			meta: {
				pageTitle: 'Catalogues',
				breadcrumb: [
					{
						text: 'Liste des catalogues',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page catalogue pdf
		{
			path: '/catalogues-pdf',
			name: 'Catalogue',
			component: () =>
				import('@/views/pages/dashboard/article/cataloguepdf.vue'),
			meta: {
				pageTitle: 'Catalogue',
				breadcrumb: [
					{
						text: 'catalogue pdf',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page Liste facture
		// {
		// 	path: '/devis',
		// 	name: 'DevisList',
		// 	component: () =>
		// 		import('@/views/pages/dashboard/devis/Liste_devis/DevisList.vue'),
		// 	meta: {
		// 		title: 'Banana fish',
		// 		pageTitle: 'Liste des devis',
		// 		breadcrumb: [
		// 			{
		// 				text: 'Devis',
		// 				active: true,
		// 			},
		// 		],
		// 		requiresAuth: true,
		// 	},
		// },

		// // Route vers la page de creation de devis
		// {
		// 	path: '/devis/create',
		// 	name: 'DevisAdd',
		// 	component: () => import('@/views/pages/dashboard/devis/Devis.vue'),
		// 	meta: {
		// 		pageTitle: 'Devis',
		// 		breadcrumb: [
		// 			{
		// 				text: 'Devis',
		// 				active: true,
		// 			},
		// 		],
		// 		requiresAuth: true,
		// 	},
		// },

		{
			path: '/creation/article',
			name: 'creation-article',
			component: () =>
				import(
					'@/views/pages/dashboard/article/qArticleAdd.vue'
				),
			meta: {
				pageTitle: 'Article',
				breadcrumb: [
					{
						text: 'Creation d\'article',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		{
			path: '/creation/client',
			name: 'creation-client',
			component: () =>
				import(
					'@/views/pages/dashboard/client/qClientAdd.vue'
				),
			meta: {
				pageTitle: 'Client',
				breadcrumb: [
					{
						text: 'Creation de client',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		/*****
		 *
		 *
		 *
		 *
		 */
		{
			path: '/facture/view',
			name: 'FacturePreview',
			component: () =>
				import(
					'@/views/pages/dashboard/facture/Apercu_facture/FacturePreview.vue'
				),
			meta: {
				pageTitle: 'Facture',
				breadcrumb: [
					{
						text: 'Details de la facture',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/devis/view',
			name: 'DevisPreview',
			component: () =>
				import(
					'@/views/pages/dashboard/facture/Apercu_facture/FacturePreview.vue'
				),
			meta: {
				pageTitle: 'Devis',
				breadcrumb: [
					{
						text: 'Details de devis',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		/*****
		 *
		 *
		 *
		 *
		 */
		{
			path: '/edit/devis',
			name: 'DevisEdit',
			component: () => import('@/views/pages/dashboard/facture/Facture.vue'),
			meta: {
				pageTitle: 'Devis',
				breadcrumb: [
					{
						text: 'Modification de devis',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/edit/facture',
			name: 'FactureEdit',
			component: () => import('@/views/pages/dashboard/facture/Facture.vue'),
			meta: {
				pageTitle: 'Facture',
				breadcrumb: [
					{
						text: 'Modification de facture',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		/*****
		 *
		 *
		 *
		 *
		 */
		{
			path: '/create/devis',
			name: 'DevisAdd',
			component: () => import('@/views/pages/dashboard/facture/Facture.vue'),
			meta: {
				pageTitle: 'Devis',
				breadcrumb: [
					{
						text: 'Creation de devis',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/create/facture',
			name: 'FactureAdd',
			component: () => import('@/views/pages/dashboard/facture/Facture.vue'),
			meta: {
				pageTitle: 'Facture',
				breadcrumb: [
					{
						text: 'Creation de facture',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		/*****
		 *
		 *
		 *
		 *
		 */

		// Route vers la page de creation de module
		{
			path: '/modules/create',
			name: 'module',
			component: () => import('@/views/pages/dashboard/module/module.vue'),
			meta: {
				pageTitle: 'Module',
				breadcrumb: [
					{
						text: 'creation de Module',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		/*****
		 *
		 *
		 *
		 *
		 */
		{
			path: '/transactions',
			name: 'transaction',
			component: () =>
				import('@/views/pages/dashboard/transaction/transaction.vue'),
			meta: {
				pageTitle: 'Transactions',
				breadcrumb: [
					{
						text: 'Liste des transactions',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de modifier de module
		{
			path: '/modules/update',
			name: 'module',
			component: () =>
				import('@/views/pages/dashboard/module/update_module.vue'),
			meta: {
				pageTitle: 'Module',
				breadcrumb: [
					{
						text: 'Modification de Module',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de d'achat de module
		{
			path: '/modules/add',
			name: 'add',
			component: () => import('@/views/pages/dashboard/module/add.vue'),
			meta: {
				pageTitle: 'Modules',
				breadcrumb: [
					{
						text: 'Acheter un Module',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de crud de modules
		{
			path: '/modules',
			name: 'listmodule',
			component: () => import('@/views/pages/dashboard/module/list_module.vue'),
			meta: {
				pageTitle: 'Module',
				breadcrumb: [
					{
						text: 'Module',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page des relances
		{
			path: '/relances',
			name: 'relance',
			component: () => import('@/views/pages/dashboard/relance/relance.vue'),
			meta: {
				pageTitle: 'Relances',
				breadcrumb: [
					{
						text: 'Liste des Relances',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page Liste facture
		{
			path: '/lists/factures',
			name: 'FactureList',
			component: () =>
				import('@/views/pages/dashboard/facture/Liste_facture/FactureList.vue'),
			meta: {
				title: 'Banana fish',
				pageTitle: 'Factures',
				breadcrumb: [
					{
						text: 'Liste des facture',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/lists/devis',
			name: 'DevisList',
			component: () =>
				import('@/views/pages/dashboard/facture/Liste_facture/FactureList.vue'),
			meta: {
				title: 'Banana fish',
				pageTitle: 'Devis',
				breadcrumb: [
					{
						text: 'Liste des devis',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de paiement
		{
			path: '/paiement',
			name: 'paiement',
			component: () => import('@/views/pages/paiement/paiement.vue'),
			meta: {
				layout: 'full',
				requiresAuth: true,
			},
		},
		//Projets
		{
			path: '/projets',
			name: 'projet',
			component: () => import('@/views/pages/dashboard/projet/projet.vue'),
			meta: {
				pageTitle: 'Projets',
				breadcrumb: [
					{
						text: 'Liste des projets',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		//Agence
		{
			path: '/agences',
			name: 'agences',
			component: () => import('@/views/pages/dashboard/agence/agence.vue'),
			meta: {
				pageTitle: 'Agences',
				breadcrumb: [
					{
						text: 'Liste des agences',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		//Departements
		{
			path: '/departements',
			name: 'departements',
			component: () => import('@/views/pages/dashboard/departements/departements.vue'),
			meta: {
				pageTitle: 'Departements',
				breadcrumb: [
					{
						text: 'Liste des departements',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		//budgets
		{
			path: '/budgets',
			name: 'budgets',
			component: () => import('@/views/pages/dashboard/budgets/budget.vue'),
			meta: {
				pageTitle: 'Budgets',
				breadcrumb: [
					{
						text: 'Liste des budgets',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers la page Aperçu facture
		// {
		// 	path: '/facture/view',
		// 	name: 'FacturePreview',
		// 	component: () =>
		// 		import(
		// 			'@/views/pages/dashboard/facture/Apercu_facture/FacturePreview.vue'
		// 		),
		// 	meta: {
		// 		requiresAuth: true,
		// 	},
		// },
		// Route vers la page Aperçu facture
		// {
		// 	path: '/facture/edit',
		// 	name: 'FactureEdit',
		// 	component: () =>
		// 		import(
		// 			'@/views/pages/dashboard/facture/Edition_facture/FactureEdit.vue'
		// 		),
		// 	meta: {
		// 		requiresAuth: true,
		// 	},
		// },
		// Route vers la page des Emprunts
		{
			path: '/emprunts',
			name: 'emprunt',
			component: () => import('@/views/pages/dashboard/emprunt/emprunt.vue'),
			meta: {
				pageTitle: 'Emprunts',
				breadcrumb: [
					{
						text: 'Liste des emprunt',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/preview-emprunt',
			name: '/preview-emprunt',
			component: () => import('@/views/pages/dashboard/emprunt/preview_emprunt.vue'),
			meta: {
				pageTitle: 'Emprunt',
				breadcrumb: [
					{
						text: 'Details de emprunt',
						active: true,
					},
					{
						text: 'Liste des emprunts',
						to: { name: 'emprunt' },
					  },

					  {
						text: 'Ajouter emprunt',
						to: { name: 'newemprunt' },
					  },
				],
				requiresAuth: true,
			},
		},

		 // Route vers la page creation des depense simple
		 {
			path: '/type-depense',
			name: 'type-depense',
			component: () => import('@/views/pages/dashboard/depense/typeDepense/type_depense.vue'),
			meta: {
			  pageTitle: 'Depenses',
			  breadcrumb: [
				{
				  text: 'Types des depenses',
				  active: true,
				},
				// {
				//   text: 'Liste des depenses simples',
				//   to: { name: 'liste-depense-simple' }
				// },
			  ],
			  requiresAuth: true
			},
		  },

   // Route vers la page creation des depense simple
   {
    path: '/depense-simple',
    name: 'depense-simple',
    component: () => import('@/views/pages/dashboard/depense/simple/depense_simple.vue'),
    meta: {
      pageTitle: 'Liste des Dépenses ',
      breadcrumb: [
        {
          text: 'Effectuer une depense',
          active: true,
        },
        {
          text: 'Liste des depenses simples',
          to: { name: 'liste-depense-simple' }
        },
      ],
      requiresAuth: true
    },
  },
 // Route vers la page preview des depenses simples
 {
  path: '/preview-depense-simple',
  name: 'preview-depense-simple',
  component: () => import('@/views/pages/dashboard/depense/simple/preview_depense_simple.vue'),
  meta: {
    pageTitle: 'Depense',
    breadcrumb: [
      {
        text: 'Detail de la depense',
        active: true,
      },
      {
        text: 'Liste des depenses simples',
        to: { name: 'liste-depense-simple' },
      },
	  {
        text: 'Ajouter une depense',
        to: { name: 'depense-simple' },
      },
    ],
    requiresAuth: true
  },
},

//   Route vers la page liste des depense simples
  {
    path: '/liste-depense-simple',
    name: 'liste-depense-simple',
    component: () => import('@/views/pages/dashboard/depense/simple/liste_depense_simple.vue'),
    meta: {
      pageTitle: 'Depense',
      breadcrumb: [
        {
          text: 'Liste des depenses',
          active: true,
        },
      ],
      requiresAuth: true
    },
  },
	
		// Route vers la page des depense simple
		{
			path: '/depense-simple',
			name: 'depense-simple',
			component: () =>
				import('@/views/pages/dashboard/depense/simple/depense_simple.vue'),
			meta: {
				pageTitle: 'Depense',
				breadcrumb: [
					{
						text: 'Effectuer une depense',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

	
		// Route vers la page des depense-groupée
		{
			path: '/depense-groupe',
			name: 'depense-groupe',
			component: () =>
				import('@/views/pages/dashboard/depense/depense_groupe.vue'),
			meta: {
				pageTitle: 'Depense groupée',
				breadcrumb: [
					{
						text: 'Effectuer une depense groupée',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de la liste des depenses groupées
		{
			path: '/liste-depense-groupe',
			name: 'liste-depense-groupe',
			component: () =>
				import('@/views/pages/dashboard/depense/liste_depense_groupe.vue'),
			meta: {
				pageTitle: 'Depense groupée',
				breadcrumb: [
					{
						text: 'Liste des depense groupées',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page des depenses recurrentes
		{
			path: '/depense-recurrente',
			name: 'depense-recurrente',
			component: () =>
				import('@/views/pages/dashboard/depense/depense_recurrente.vue'),
			meta: {
				pageTitle: 'Depense',
				breadcrumb: [
					{
						text: 'Effectuer une depense recurrente',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de la liste des depenses recurrentes
		{
			path: '/liste-depense-recurrente',
			name: 'liste-depense-recurrente',
			component: () =>
				import('@/views/pages/dashboard/depense/liste_depense_recurrente.vue'),
			meta: {
				pageTitle: 'Depense recurrente',
				breadcrumb: [
					{
						text: 'Liste des depense recurrente',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page de fournisseur
		{
			path: '/fournisseurs',
			name: 'fournisseurs',
			component: () =>
				import('@/views/pages/dashboard/fournisseur/fournisseur.vue'),
			meta: {
				pageTitle: 'Fournisseurs',
				breadcrumb: [
					{
						text: 'Liste des fournisseurs',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/detail',
			name: 'détail-fournisseur',
			component: () =>
				import('@/views/pages/dashboard/fournisseur/detail/ClientDetail.vue'),
			meta: {
				pageTitle: 'Fournisseur ',
				breadcrumb: [
					{
						text: 'Détail de fournisseur',
						active: true,
						to: { name: 'fournisseurs' },
					},
					{
						text: 'Retour',
						to: { name: 'fournisseurs' },
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers la page des departements
		{
			path: '/departements',
			name: 'departement',
			component: () =>
				import('@/views/pages/dashboard/departement/departement.vue'),
			meta: {
				pageTitle: 'Département',
				breadcrumb: [
					{
						text: 'departement',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers la page 404
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		// Route vers new emprunt
		{
			path: '/newemprunt',
			name: 'newemprunt',
			component: () => import('@/views/pages/dashboard/emprunt/newemprunt.vue'),
			meta: {
				pageTitle: 'Emprunt',
				breadcrumb: [
					{
						text: 'Emprunt',
						active: true,
					},
					{
						text: 'Liste des emprunts',
						to: { name: 'emprunt' }
					  },
				],
				requiresAuth: true,
			},
		},
		// Route vers new creation des employees
		{
			path: '/createUsers',
			name: 'createUsers',
			component: () => import('@/views/pages/dashboard/user/createUser.vue'),
			meta: {
				pageTitle: 'Gestion des utilisateurs',
				breadcrumb: [
					{
						text: "Creation d'Users",
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		// Route vers new creation d'un nouvel utilisateurs
		{
			path: '/employes',
			name: 'employes',
			component: () => import('@/views/pages/dashboard/user/newUsers.vue'),
			meta: {
				pageTitle: 'Employés',
				breadcrumb: [
					{
						text: 'Liste des employées',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers detail d'un employe
		{
			path: '/detail/employe',
			name: 'détail employe',
			component: () =>
				import('@/views/pages/dashboard/user/detail/detail_users.vue'),
			meta: {
				pageTitle: 'Employés',
				breadcrumb: [
					{
						text: "Détail d'employe",
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route vers detail d'un employe
		// {
		// 	path: '/inventaires',
		// 	name: 'inventaire',
		// 	component: () =>
		// 		import('@/views/pages/dashboard/inventaire/inventaire.vue'),
		// 	meta: {
		// 		pageTitle: 'Iventaire',
		// 		breadcrumb: [
		// 			{
		// 				text: 'Créer un inventaire',
		// 				active: true,
		// 			},
		// 		],
		// 		requiresAuth: true,
		// 	},
		// },



		{
			path: '/prospects/domaines',
			name: 'prospectDomaines',
			component: () =>
				import('@/views/pages/dashboard/prospection/domaine/index.vue'),
			meta: {
				pageTitle: 'Prospection',
				breadcrumb: [
					{
						text: "Liste des domaines d'activite",
						active: true,
					}
				],
				requiresAuth: true,
			},
		},




		{
			path: '/prospection-detail',
			name: 'prospection-detail',
			component: () =>
				import('@/views/pages/dashboard/prospection/preview_prospection.vue'),
			meta: {
				pageTitle: 'Prospection',
				breadcrumb: [
					{
						text: 'Detail de la prospection',
						active: true,
					},
					{
						text: 'Liste des prospections',
						to: { name: 'liste-prospection' }
					  },
					  {
						text: 'Ajouter une prospection',
						to: { name: 'prospection' }
					  },
				],
				requiresAuth: true,
			},
		},


		

		// Route vers detail d'une prospection
		{
			path: '/liste-prospection',
			name: 'liste-prospection',
			component: () =>
				import('@/views/pages/dashboard/prospection/prospection_liste.vue'),
			meta: {
				pageTitle: 'Prospection',
				breadcrumb: [
					{
						text: 'Créer une prospection',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/prospection',
			name: 'prospection',
			component: () =>
				import('@/views/pages/dashboard/prospection/prospection_create.vue'),
			meta: {
				pageTitle: 'Prospection',
				breadcrumb: [
					{
						text: 'Créer une prospection',
						active: true,
					},
					{
						text: 'Liste des prospections',
						to: { name: 'liste-prospection' }
					  },
				],
				requiresAuth: true,
			},
		},

		// Route vers detail d'une prospection
		{
			path: '/prospect',
			name: 'prospect',
			component: () =>
				import('@/views/pages/dashboard/prospection/prospect.vue'),
			meta: {
				pageTitle: 'Prospection',
				breadcrumb: [
					{
						text: 'Créer un prospect',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route création de permission
		{
			path: '/permissions',
			name: 'permission',
			component: () =>
				import('@/views/pages/dashboard/permission/permission.vue'),
			meta: {
				pageTitle: 'Permissions',
				breadcrumb: [
					{
						text: 'créer une permission',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route création de permission
		{
			path: '/roles',
			name: 'role',
			component: () => import('@/views/pages/dashboard/role/role.vue'),
			meta: {
				pageTitle: 'Roles',
				breadcrumb: [
					{
						text: 'créer un role',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Route update de permission
		{
			path: '/updateRole',
			name: '',
			component: () => import('@/views/pages/dashboard/role/updateRole.vue'),
			meta: {
				pageTitle: 'Roles',
				breadcrumb: [
					{
						text: 'créer un role',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},


		// // Route vers new emprunt
		// {
		//   path: '/newemprunt',
		//   name: 'emprunt',
		//   component: () => import('@/views/pages/dashboard/emprunt/newemprunt.vue'),
		//   meta: {
		//     pageTitle: 'Emprunt',
		//     breadcrumb: [
		//       {
		//         text: 'Emprunt',
		//         active: true,
		//       },
		//     ],
		//     requiresAuth: true
		//   },
		// },

		// ALL ROUTE FOR CATEGORIE
		{
			path: '/categories',
			name: 'CategorieArticles',
			component: () =>
				import('@/views/pages/dashboard/categorie/categorie.vue'),
			meta: {
				pageTitle: 'Categories',
				breadcrumb: [
					{
						text: 'Liste des categories',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		{
			path: '/categories/detail',
			name: 'CategorieArticlesDetail',
			component: () =>
				import('@/views/pages/dashboard/categorie/categorie.vue'),
			meta: {
				pageTitle: 'Categories',
				breadcrumb: [
					{
						text: 'Details de categorie',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},

		// Redirige vers la page 404 au cas où la route pointé ne figure pas dans la liste des routes
		{
			path: '*',
			redirect: 'error-404',
		},
	],
});

router.beforeEach((to, from, next) => {
	// Determine if the route requires authentication
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// Get value from somewhere to determine if the user is
		// logged in or not
		let isLoggedIn = 'NotAuthenticated';
		
		if (localStorage.getItem('token')) {

			isLoggedIn = localStorage.getItem('qIsNotAuthenticated') === null ? 'NotAuthenticated' : qDecrypted(localStorage.getItem('qIsNotAuthenticated'));
			
		}
		// console.log(isLoggedIn)

		// If user is not logged in, navigate to the named "login" route
		// with a query string parameter indicating where to navigate to after
		// successful login
		if (isLoggedIn === 'NotAuthenticated') {
			// Navigate to login route
			next({
				name: 'login',
				query: { redirect: to.fullPath },
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg');
	if (appLoading) {
		appLoading.style.display = 'none';
	}
});

export default router;
