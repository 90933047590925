<template>
   <div id="app" class="h-100 w-100" :class="[skinClasses]">
      <component :is="layout"> <router-view />{{ VERIFY_TOKEN }} </component>
   </div>
</template>

<script>
// This will be populated in `beforeCreate` hook

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   ref,
} from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';
import qeEntrepriseInfo from './services/qeEntrepriseInfo';
import { qCryptJson, qDecrypted, qDecryptedJson } from './services/qeCrypt';
import { BIconNodePlusFill } from 'bootstrap-vue';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutHorizontal = () =>
   import('@/layouts/horizontal/LayoutHorizontal.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');
import { useRouter } from './@core/utils/utils';

export default {
   components: {
      // Layouts
      LayoutHorizontal,
      LayoutVertical,
      LayoutFull,
   },

   // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
   // Currently, router.currentRoute is not reactive and doesn't trigger any change
   computed: {
      layout() {
         if (this.$route.meta.layout === 'full') return 'layout-full';
         return `layout-${this.contentLayoutType}`;
      },
      contentLayoutType() {
         return this.$store.state.appConfig.layout.type;
      },
   },
   beforeCreate() {
      // Set colors in theme
      const colors = [
         'primary',
         'secondary',
         'success',
         'info',
         'warning',
         'danger',
         'light',
         'dark',
      ];

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = colors.length; i < len; i++) {
         $themeColors[colors[i]] = useCssVar(
            `--${colors[i]}`,
            document.documentElement
         ).value.trim();
      }

      // Set Theme Breakpoints
      const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = breakpoints.length; i < len; i++) {
         $themeBreakpoints[breakpoints[i]] = Number(
            useCssVar(
               `--breakpoint-${breakpoints[i]}`,
               document.documentElement
            ).value.slice(0, -2)
         );
      }

      // Set RTL
      const { isRTL } = $themeConfig.layout;
      document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
   },
   setup(props, { root }) {
      const { skin, skinClasses } = useAppConfig();
      const isLoggedIn = ref('NotAuthenticated');

      onBeforeMount(() => {
         //GET CURRENT USER

        

         isLoggedIn.value =
            localStorage.getItem('qIsNotAuthenticated') === null
               ? 'NotAuthenticated'
               : qDecrypted(localStorage.getItem('qIsNotAuthenticated'));
         if (isLoggedIn.value === 'isAuthenticated') {
            axios
               .get(URL.PERMISSION_ALL)
               .then((response) => {
                  // console.log(response.data);
                  localStorage.setItem('qPerm', qCryptJson(response.data[0]));
               })
               .catch((error) => {
                  console.log(error);
               });
         }
      });

      onUpdated(() => {
         // if (localStorage.getItem('qResubscription') != null) {
         //    if (
         //       qDecryptedJson(localStorage.getItem('qResubscription')).key ===
         //          true &&
         //       useRouter().route.value.path !== '/paiement'
         //    ) {
         //       localStorage.removeItem('qResubscription');
         //    }
         // }

         if (isLoggedIn.value === 'isAuthenticated') {
            qeEntrepriseInfo.info();

            // IF ENTREPRISE IS NOT CREATE
            const isCreateEntrprise =
               localStorage.getItem('qhze_001') === null
                  ? 'false'
                  : qDecrypted(localStorage.getItem('qhze_001'));
            if (isCreateEntrprise === 'true') {
               if (root.$route.name === 'entreprise') {
                  return;
               } else {
                  root.$router.replace({ name: 'entreprise' });
               }
            }

            /* IF PAYMENT IS NOT TERMINATED
            if (isCreateEntrprise !== 'true') {
               const isPaymentTerminated =
                  localStorage.getItem('qhze_022') === null
                     ? null
                     : qDecryptedJson(localStorage.getItem('qhze_022'));
                console.log(isPaymentTerminated);
               // console.log('isPaymentTerminated', isPaymentTerminated);
               if (isPaymentTerminated !== null) {
                  const xSubcribleIsExpired =
                     isPaymentTerminated.nombre_jour_restant <= 0
                        ? true
                        : false;

                  const xResubscriptionFormPack =
                     localStorage.getItem('qResubscription') !== null
                        ? qDecryptedJson(
                             localStorage.getItem('qResubscription')
                          )
                        : { key: false };
                  // console.log(xSubcribleIsExpired, xResubscriptionFormPack);

                  if (
                     xSubcribleIsExpired === true &&
                     xResubscriptionFormPack.key === false
                  ) {
                     if (root.$route.name === 'pack') {
                        return;
                     } else {
                        root.$router.replace({ name: 'pack' });
                     }
                  }
               }
            }*/
         }
      });

      

      const VERIFY_TOKEN = onUpdated(async () => {
         axios.get(URL.VERIFY_TOKEN).then((response) => {
            let valide = response.data.valid;
            if (!valide && localStorage.getItem('token')) {
               localStorage.removeItem('token');
               location.reload();
            }
         });
      });

      // If skin is dark when initialized => Add class to body
      if (skin.value === 'dark') document.body.classList.add('dark-layout');

      // Provide toast for Composition API usage
      // This for those apps/components which uses composition API
      // Demos will still use Options API for ease
      provideToast({
         hideProgressBar: true,
         closeOnClick: false,
         closeButton: false,
         icon: false,
         timeout: 3000,
         transition: 'Vue-Toastification__fade',
      });

      // Set Window Width in store
      store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
      const { width: windowWidth } = useWindowSize();
      watch(windowWidth, (val) => {
         store.commit('app/UPDATE_WINDOW_WIDTH', val);
      });

      return {
         skinClasses,
         VERIFY_TOKEN,
      };
   },
};
</script>
<style>
/* @import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css'); */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   /* display: none; <- Crashes Chrome on hover */
   -webkit-appearance: none;
   margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.pac-container {
   @apply z-40;
}

input[type='number'] {
   -moz-appearance: textfield; /* Firefox */
}
</style>
